import React from "react";
import { graphql } from "gatsby";
import { SplashEvents } from "./SplashEvents";
import { KontentComponentSettings, StringValue } from "../../../types";
import { KontentComponentLayout } from "../../../types/index";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings, KontentComponentLayout {
  heading: StringValue;
}

const SplashEventsContainer: React.FC<Props> = ({
  heading,
  component_layout__component_width,
  ...props
}) => {
  const componentWidth = getMultiChoice(component_layout__component_width);

  return <SplashEvents width={componentWidth} {...props} />;
};

export default SplashEventsContainer;

export const fragmentSplashEventsContainer = graphql`
  fragment SplashEventsContainer on kontent_item_component___splash_events {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      heading {
        value
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      component_layout__component_width {
        value {
          codename
        }
      }
    }
  }
`;
