import { graphql, useStaticQuery } from "gatsby";
import { KontentContentTile, KontentRelatedItem } from "../types/index";

interface KontentRecentScientist {
  allKontentItemPage: {
    nodes: {
      id: string;
      elements: KontentRelatedItem["elements"];
    }[];
  };
}

export const useRecentScientistTile = (): KontentContentTile["elements"] => {
  const recentScientist = useStaticQuery<KontentRecentScientist>(graphql`
    {
      allKontentItemPage(
        filter: {
          elements: {
            content: {
              value: {
                elemMatch: {
                  internal: { type: { eq: "kontent_item_content___sotd" } }
                }
              }
            }
          }
        }
        sort: { elements: { display_publish_date: { value: DESC } } }
        limit: 1
      ) {
        nodes {
          id
          elements {
            content {
              value {
                ... on kontent_item_content___sotd {
                  __typename
                  system {
                    name
                  }
                  elements {
                    content_tile {
                      value {
                        ... on kontent_item_element___content_tile {
                          ...kontentContentTile
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return recentScientist.allKontentItemPage?.nodes[0].elements?.content
    ?.value[0]?.elements?.content_tile?.value[0]?.elements;
};
