import React, { useState } from "react";
import * as styles from "./SplashEvents.module.css";
import {
  InstantSearch,
  Configure,
  InfiniteHits,
  connectStateResults,
} from "react-instantsearch-dom";
import { searchIndex, searchClient } from "../../../lib/algolia";
import DebouncedSearchBox from "../../elements/SearchInput/DebouncedSearchBox";
import {
  ComponentProps,
  ComponentWidth,
  KontentContentTile,
} from "../../../types/index";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { SearchState } from "react-instantsearch-core";
import { ContentTile } from "../../elements/ContentTile/ContentTile";
import { formatContentTiles } from "../../../lib/contentTiles";

interface Props extends ComponentProps, ComponentWidth {
  searchTerm?: SearchState;
  onSearchTermChange?: (arg: string) => void;
  createURL?: (arg: string) => string;
}

interface AlgoliaMatch {
  value: string;
  matchLevel: string;
  matchedWords: string[];
}
interface SearchResult {
  url: string;
  title: string;
  objectId: string;
  description: string;
  contentCategory: string;
  content: any; // page content components
  _hightlightResult: {
    url: AlgoliaMatch;
    title: AlgoliaMatch;
    description: AlgoliaMatch;
    contentCategory: string;
  };
  contentTile: KontentContentTile["elements"];
}

interface HitItem {
  hit: SearchResult;
}

export const SplashEvents: React.FC<Props> = (props) => {
  if (typeof document === "undefined") return null;
  let todayMidnight: Date | Number = new Date();
  todayMidnight.setHours(0, 0, 0, 0);
  todayMidnight = Math.round(todayMidnight.getTime() / 1000);
  const futureFilter = `startDate > ${todayMidnight}`;
  const pastFilter = `startDate < ${todayMidnight}`;
  const featuredFilter = `featured.codename:yes`;
  const [currentFilter, setCurrentFilter] = useState(futureFilter);
  const [currentIndex, setCurrentIndex] = useState(searchIndex.events);
  const [placeholder, setPlaceholder] = useState("upcoming");
  let sectionClassName = styles.splashEventsContainer;
  if (props.width === "contained") {
    sectionClassName += ` ${styles.contained}`;
  }

  //@ts-ignore
  const ResultWrapper = connectStateResults(({ searchResults, children }) => {
    return searchResults && searchResults.nbHits !== 0 ? (
      <div>{children}</div>
    ) : (
      <div className={styles.resultsInfo}>
        No results found for {searchResults?.query}{" "}
      </div>
    );
  });

  const Hit = (props: HitItem) => {
    if (!props?.hit?.contentTile) {
      return null;
    }

    let tileContent = formatContentTiles([{ elements: props.hit.contentTile }]);

    if (tileContent?.length) {
      tileContent = tileContent[0];
    }

    return (
      <div className={styles.hit}>
        <ContentTile tileWidth="oneThird" {...tileContent} />
      </div>
    );
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceholder(e.target.value);

    switch (e.target.value) {
      case "upcoming":
        setCurrentFilter(futureFilter);
        if (currentIndex !== searchIndex.events) {
          setCurrentIndex(searchIndex.events);
        }
        break;
      case "featured":
        setCurrentFilter(featuredFilter);
        if (currentIndex !== searchIndex.events) {
          setCurrentIndex(searchIndex.events);
        }
        break;
      case "popular":
        setCurrentIndex(searchIndex.eventsPopular);
        if (currentFilter !== futureFilter) {
          setCurrentFilter(futureFilter);
        }
        break;
      case "past":
        setCurrentFilter(pastFilter);
        if (currentIndex !== searchIndex.eventsPast) {
          setCurrentIndex(searchIndex.eventsPast);
        }
        break;
    }
  };

  return (
    <SectionWrapper {...props} domElement="div">
      <div className={sectionClassName}>
        <h2 className={styles.heading}>Events</h2>
        <InstantSearch
          indexName={currentIndex}
          searchClient={searchClient}
          searchState={props.searchTerm}
          onSearchStateChange={props.onSearchTermChange}
          createURL={props.createURL}
        >
          <Configure hitsPerPage={20} filters={currentFilter} />

          <div className={styles.searchContainer}>
            <DebouncedSearchBox
              placeHolder={`Search ${placeholder} events...`}
              defaultRefinement={props.searchTerm?.query}
              delay={200}
            />
          </div>
          <fieldset className={styles.searchOptions}>
            <div className={styles.radio}>
              <input
                type="radio"
                name="search-option"
                id="search-upcoming"
                className="vis-hidden"
                value="upcoming"
                defaultChecked={true}
                onChange={handleFilterChange}
              />
              <label htmlFor="search-upcoming">Upcoming</label>
            </div>
            <div className={styles.radio}>
              <input
                type="radio"
                name="search-option"
                id="search-featured"
                className="vis-hidden"
                value="featured"
                onChange={handleFilterChange}
              />
              <label htmlFor="search-featured">Featured</label>
            </div>
            <div className={styles.radio}>
              <input
                type="radio"
                name="search-option"
                id="search-popular"
                className="vis-hidden"
                value="popular"
                onChange={handleFilterChange}
              />
              <label htmlFor="search-popular">Popular</label>
            </div>
            <div className={styles.radio}>
              <input
                type="radio"
                name="search-option"
                id="search-past"
                className="vis-hidden"
                value="past"
                onChange={handleFilterChange}
              />
              <label htmlFor="search-past">Past</label>
            </div>
          </fieldset>
          <div className={styles.results}>
            <ResultWrapper>
              <InfiniteHits
                hitComponent={Hit}
                translations={{ loadMore: "Show More" }}
              />
            </ResultWrapper>
          </div>
        </InstantSearch>
      </div>
    </SectionWrapper>
  );
};
